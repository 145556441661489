import React, { useEffect } from "react";

const IntegrasiPromotion = () => {
  useEffect(() => {
    document.title = "Ada Integrasi - Integrasi Promotion";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Section Hero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-12">
            <h1>Integrasi Promotion</h1>
            <p>
              Jangan pusing nyari KOL marketing atau agency buat nge-brand
              produkmu, dengan Integrasi promotion, semuanya jadi mudah. Kita
              bakal bantu cariin influencer dan afiliasi yang sesuai budget kamu
              dan analisis dari kita, fokus ke minat audiens target brand dan
              produk kamu di sosmed. Plus, kita juga akan ngehubungin kamu ke
              platform marketplace yang pas dengan stok yang kamu punya di sana.
              Gak percaya? Cobain aja sekarang.
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrasiPromotion;
