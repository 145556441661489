import React from "react";
import Tagify from "@yaireo/tagify";
import { Select2 } from "select2";
import $ from "jquery";
import "select2/dist/css/select2.min.css";

const LacakPaket = () => {
  React.useEffect(() => {
    document.title = "Ada Integrasi - Lacak Paket";
    const input = document.querySelector("#awb");
    const tagify = new Tagify(input, {
      maxTags: 10,
    });

    function onAddTag(e) {
      if (tagify.value.length > 10) {
        tagify.removeTags();
      }
    }

    window.scrollTo(0, 0);

    $(".form-select").select2();
  }, []);

  return (
    <div>
      <div className="Section Hero">
        <div className="container">
          <div className="row justify-content-center">
            <div className="col-sm-8 col-12">
              <h1 className="text-center">Lacak Paket</h1>
              <p className="text-center">
                Cek status pengiriman paket Anda dengan memasukkan nomor resi
                dan pilih kurir yang Anda gunakan. Cek hingga 10 nomor resi
                sekaligus.
              </p>
              <form>
                <div className="mb-3">
                  <label htmlFor="courier" className="form-label">
                    Kurir
                  </label>
                  <select className="form-select" id="courier" name="courier">
                    <option defaultValue={""} disabled selected>Pilih Kurir</option>
                    <option value="tiki">TIKI</option>
                    <option value="lalamove">Lalamove</option>
                    <option value="idx">ID Express</option>
                    <option value="sicepat">Sicepat</option>
                  </select>
                </div>
                <div className="mb-3">
                  <label htmlFor="noResi" className="form-label">
                    No. Resi
                  </label>
                  <div className="mb-3">
                    <input
                      placeholder="Masukkan No. Resi Anda (Max 10 resi)"
                      id="awb"
                      name="awb"
                      data-max-tags="10"
                      className="form-control"
                    />
                  </div>
                </div>
                <div className="text-end">
                  <button type="submit" className="btn Btn_purple">
                    Lacak Paket
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default LacakPaket;
