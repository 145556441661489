import React, { useEffect } from "react";
// import { Bg, Abel, Ester, Felix, Awiez } from "../AssetsImport";

const AboutUs = () => {
  useEffect(() => {
    document.title = "Ada Integrasi - Tentang Kami";
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Section Hero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-12">
            <div className="text-center">
              <h1>Tentang Kami</h1>
              <p>
                ADAINTEGRASI bukan sekedar platform integrasi biasa ; ini adalah
                mixtape terkini di platform marketplace seperti Tokopedia,
                Lazada, dan Shopee Blibli & Tiktokshop, yang lebih keren nya
                lagi Ini nyambung banget loh sama logistics big players dengan
                harga pengiriman yang kompetitif (Lalamove, Sicepat, IDexpress,
                Grab, Gojek, Tiki, Lionparcel, dan JNE) dan sosial media
                (Instagram, Tiktok, Twitter, dan Linkedin), jadi pusat aksi
                banget buat brandmu Produkmu jadi keren banget di-promosiin sama
                influencer atau afiliasi pilihan, yang semua bisa disesuaikan
                dengan budgetmu. Dari bikin branding keren sampe nge-handle
                pasar, analisa jualan, sampe bikin audiensmu tertarik di semua
                platform dan sosmed di mana produkmu jadi bintang dan
                penjualanmu akan meledak, di tambah hemat biaya pengiriman,
                hemat biaya promosi, dan hemat biaya lainnya,dijamin penjualanmu
                bakal naik sesuai dengan yang kamu inginkan. ini adalah platform
                satu integrasi terbaik buat brand kamu mengedepankan satu
                teknologi di era serba praktis terkini.
              </p>
            </div>
          </div>
          {/* <div className="col-sm-10 col-12">
            <div className="row">
              <div className="col-sm-3 col-12">
                <div className="card mb-sm-0 mb-3">
                  <img
                    src={Abel}
                    alt=""
                    className="img-card-top About_us_card_img"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Abel</h5>
                    <p className="card-text">CEO</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-12">
                <div className="card mb-sm-0 mb-3">
                  <img
                    src={Ester}
                    alt=""
                    className="img-card-top About_us_card_img"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Ester</h5>
                    <p className="card-text">CMO</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-12">
                <div className="card mb-sm-0 mb-3">
                  <img
                    src={Felix}
                    alt=""
                    className="img-card-top About_us_card_img"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Felix</h5>
                    <p className="card-text">CTO</p>
                  </div>
                </div>
              </div>
              <div className="col-sm-3 col-12">
                <div className="card mb-sm-0 mb-3">
                  <img
                    src={Awiez}
                    alt=""
                    className="img-card-top About_us_card_img"
                  />
                  <div className="card-body text-center">
                    <h5 className="card-title">Awiez</h5>
                    <p className="card-text">Web Programmer</p>
                  </div>
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
};

export default AboutUs;
