import React from 'react'
import { Bg, HargaBersaing, HematBiaya, SatuUntukSemua } from '../../AssetsImport'

const WhyUs = () => {
  return (
    <div className='Section WhyUs' style={{backgroundColor: "#faf5f7"}}>
        <div className="container">
            <h2>Mengapa Harus Integrasi?</h2>
            <div className="row">
                <div className="col-sm-6 col-12 mb-sm-4 mb-2">
                    <img className='Icon' src={HargaBersaing} alt="" />
                    <h4>Harga Bersaing</h4>
                    <p>Harga pengiriman yang kami tawarkan sangat bersaing, bahkan bisa lebih murah dari harga yang kamu dapatkan di tempat lain.</p>
                </div>
                <div className="col-sm-6 col-12 mb-sm-4 mb-2">
                    <img className='Icon' src={HematBiaya} alt="" />
                    <h4>Hemat Biaya</h4>
                    <p>Hemat biaya pengiriman, hemat biaya promosi, dan hemat biaya lainnya. Dijamin penjualanmu bakal naik sesuai dengan yang kamu inginkan.</p>
                </div>
                <div className="col-sm-6 col-12 mb-sm-4 mb-2">
                    <img className='Icon' src={SatuUntukSemua} alt="" />
                    <h4>Satu Integrasi Untuk Semua</h4>
                    <p>Tanggung jawab kami meliputi pengelolaan produk kamu mulai dari branding hingga penjualan produk, dasbor penjualan, dan menjangkau pembeli kamu di semua platform marketplace & media sosial tempat menjual produk kamu.</p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default WhyUs