import React from "react";
import HeaderComponent from "../components/home/HeaderComponent";
import AdaServices from "../components/home/AdaServices";
import CourierPartners from "../components/home/CourierPartners";
import OurInfluencers from "../components/home/OurInfluencers";
import OurMarketplacePartners from "../components/home/OurMarketplacePartners";
import WhyUs from "../components/home/WhyUs";
import Banner from "../components/home/Banner";
import Faq from "../components/home/Faq";
import Meta from "../components/Meta";

const Home = () => {
  React.useEffect(() => {
    document.title = "Ada Integrasi - Home";
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <Meta />
      <HeaderComponent />
      <AdaServices />
      <CourierPartners />
      <OurInfluencers />
      <OurMarketplacePartners />
      <WhyUs />
      <Banner />
      <Faq />
    </>
  );
};

export default Home;
