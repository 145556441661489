import React from "react";
import { Select2 } from "select2";
import $ from "jquery";

const CheckShippingCost = () => {
  React.useEffect(() => {
    document.title = "Ada Integrasi - Cek Ongkos Kirim";
    $(".form-select").select2();
    window.scrollTo(0, 0);
  }, []);
  
  
  const test = (e) => {
    e.preventDefault();

    $(".result").removeClass("d-none");
  }



  return (
    <div className="Section Hero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-12">
            <div className="text-center">
              <h1>Cek Ongkos Kirim</h1>
              <p>
                Cek ongkos kirim paket Anda dengan memasukkan kota asal, kota
                tujuan, dan berat paket. Dapatkan informasi ongkos kirim dari
                berbagai kurir yang Anda gunakan.
              </p>
            </div>

            <form>
              <div className="row">
                <div className="col-sm-6 col-12">
                  <div className="mb-3">
                    <label htmlFor="origin" className="form-label">
                      Kota Asal
                    </label>

                    <select name="origin" id="origin" className="form-select">
                      <option value="">Pilih Kota Asal</option>
                      <option value="1">Jakarta</option>
                      <option value="2">Bandung</option>
                      <option value="3">Surabaya</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="mb-3">
                    <label htmlFor="destination" className="form-label">
                      Kota Tujuan
                    </label>
                    <select
                      name="destination"
                      id="destination"
                      className="form-select"
                    >
                      <option value="">Pilih Kota Tujuan</option>
                      <option value="1">Jakarta</option>
                      <option value="2">Bandung</option>
                      <option value="3">Surabaya</option>
                    </select>
                  </div>
                </div>

                <div className="col-sm-6 col-12">
                  <div className="mb-3">
                    <label htmlFor="weight" className="form-label">
                      Berat Paket (gram)
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="weight"
                      name="weight"
                      placeholder="Berat Paket (gram)"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="mb-3">
                    <label htmlFor="length" className="form-label">
                      Panjang
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="length"
                      name="length"
                      placeholder="Panjang Paket (cm)"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="mb-3">
                    <label htmlFor="width" className="form-label">
                      Lebar
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="width"
                      name="width"
                      placeholder="Lebar Paket (cm)"
                    />
                  </div>
                </div>
                <div className="col-sm-6 col-12">
                  <div className="mb-3">
                    <label htmlFor="height" className="form-label">
                      Tinggi
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="height"
                      name="height"
                      placeholder="Tinggi Paket (cm)"
                    />
                  </div>
                </div>
              </div>
              <div className="text-end">
                <button type="submit" onClick={test} className="btn Btn_purple">
                  Cek Ongkos Kirim
                </button>
              </div>
            </form>
          </div>
        </div>

        <div className="row justify-content-center result d-none">
          <div className="col-sm-8 col-12">
            <div className="card mt-5">
              <div className="card-body">
                <h5 className="card-title">JNE</h5>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">REG (2-4 Hari)</div>
                      <div className="col-6 text-center">Rp 10.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">OKE (4-7 Hari)</div>
                      <div className="col-6 text-center">Rp 8.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">YES (1 Hari)</div>
                      <div className="col-6 text-center">Rp 20.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">POS Indonesia</h5>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">Express Next Day</div>
                      <div className="col-6 text-center">Rp 25.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">Express Same Day</div>
                      <div className="col-6 text-center">Rp 30.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">TIKI</h5>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">REG (2-4 Hari)</div>
                      <div className="col-6 text-center">Rp 10.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">ECO (4-7 Hari)</div>
                      <div className="col-6 text-center">Rp 8.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="card mt-3">
              <div className="card-body">
                <h5 className="card-title">SiCepat</h5>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">REG (2-4 Hari)</div>
                      <div className="col-6 text-center">Rp 10.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="card mb-2">
                  <div className="card-body">
                    <div className="row">
                      <div className="col-3">Ekonomi (4-7 Hari)</div>
                      <div className="col-6 text-center">Rp 8.000</div>
                      <div className="col-3 text-end">
                        <button className="btn Btn_purple">Pilih</button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CheckShippingCost;
