import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="Section Hero container mb-5 pb-5">
      <div className="row justify-content-center mt-5">
        <div className="col-sm-8 col-12">
          <h1>404 - Not Found</h1>
          <p>
            The page you are looking for might have been removed, had its name
            changed or is temporarily unavailable.
          </p>
          <Link to={"/"} className="btn Btn_purple">
            Go to Home
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
