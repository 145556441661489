import React, { useEffect } from "react";

const IntegrasiShipping = () => {
  document.title = "Ada Integrasi - Integrasi Shipping";
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div className="Section Hero">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-sm-8 col-12">
            <h1>Integrasi Shipping</h1>
            <p>
              Jangan pusing ngatur logistik pengiriman penjualan kamu untuk
              menentukan mana ekpedisi yang bagus sesuai kebutuhan dan
              kompetitif, Karna ADAINTEGRASI sebagai aggregator logistik untuk
              pengiriman terintegrasi sama beberapa logistik top, akan
              menyesuaikan banget pengiriman sesuai kebutuhanmu dengan harga
              yang pas!
              <br />
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default IntegrasiShipping;
