import React from "react";
import { Helmet } from "react-helmet";

const Meta = () => {
  return (
    <div>
      <Helmet>
        <meta name="description" content="Ada Integras bukan sekedar platform integrasi biasa; ini adalah mixtape terkini di platform marketplace seperti Tokopedia, Lazada, dan Shopee Blibli & Tiktokshop, yang lebih keren nya lagi Ini nyambung banget loh sama logistics big players dengan harga pengiriman yang kompetitif" />
        <meta name="keywords" content="ada integrasi, jasa pengiriman, jasa promosi, agregator, logistik, agregator logistik" />
        <meta name="author" content="PT Ada Integrasi Indonesia" />
        <meta name="twitter:title" content="PT Ada Integrasi Indonesia" />
        <meta
          name="twitter:description"
          content="Ada Integrasi bukan sekedar platform integrasi biasa; ini adalah mixtape terkini di platform marketplace seperti Tokopedia, Lazada, dan Shopee Blibli & Tiktokshop, yang lebih keren nya lagi Ini nyambung banget loh sama logistics big players dengan harga pengiriman yang kompetitif"
        />
      </Helmet>
    </div>
  );
};

export default Meta;
