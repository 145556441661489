import React from "react";
import { Bg, AdaIntegrasiLogo } from "../AssetsImport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faEnvelope,
  faMap,
  faMapMarked,
  faMapMarkedAlt,
  faMapPin,
  faPhone,
} from "@fortawesome/free-solid-svg-icons";

const FooterComponent = () => {
  return (
    <div className="Section Footer" style={{ backgroundImage: `url('${Bg}')` }}>
      <div className="container">
        <img className="Footer_logo" src={AdaIntegrasiLogo} alt="" />

        <div className="row">
          <div className="col-sm-4 col-12 mb-sm-0 mb-3">
            <h1 className="Footer_title">Hubungi Kami</h1>
            <p>PT ADA Integrasi Indonesia</p>

            <br />

            <a href="#" className="d-flex">
              <FontAwesomeIcon
                icon={faMapMarkedAlt}
                className="fa-2x me-3 Color_purple"
              />
              <p>
                Kompleks Pergudangan Bizhub Blok GE No. 15, Jl. Raya Serpong,
                Pabuaran, Kec. Gn. Sindur, Kabupaten Bogor, Jawa Barat 16340,
                Indonesia
              </p>
            </a>
            <a
              href="mailto:info@integrasi.co.id"
              target="_blank"
              className="d-flex mb-3 align-items-center"
            >
              <FontAwesomeIcon
                icon={faEnvelope}
                className="fa-2x me-3 Color_purple"
              />
              <p className="m-0">info@integrasi.co.id</p>
            </a>
            <a
              href="tel:622123456789"
              className="d-flex mb-3 align-items-center"
            >
              <FontAwesomeIcon
                icon={faPhone}
                className="fa-2x me-3 Color_purple"
              />
              <p className="m-0">02123456789</p>
            </a>
          </div>
          <div className="col-sm-4 col-12 mb-sm-0 mb-3">
            <h1 className="Footer_title">Produk</h1>
            <ul className="List_plain">
              <li>
                <a href="/">Integrasi Shipping</a>
              </li>
              <li>
                <a href="/">Integrasi Promotion</a>
              </li>
            </ul>
          </div>
          <div className="col-sm-4 col-12 mb-sm-0 mb-3">
            <h1 className="Footer_title">Layanan</h1>
            <ul className="List_plain">
              <li>
                <a href="/">Lacak Paket</a>
              </li>
              <li>
                <a href="/">Cek Ongkos Kirim</a>
              </li>
            </ul>
          </div>
        </div>

        <div className="row">
            <div className="col-sm-6 col-12">
                <p className="mt-5 mb-0">
                    © 2024 PT ADA Integrasi Indonesia. All Rights Reserved.
                </p>
            </div>
        </div>
      </div>
    </div>
  );
};

export default FooterComponent;
