import React from "react";
import ReactDOM from "react-dom/client";
import "./index.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound from "./components/home/NotFound";
import LacakPaket from "./pages/LacakPaket";
import CheckShippingCost from "./pages/CheckShippingCost";
import IntegrasiShipping from "./pages/IntegrasiShipping";
import IntegrasiPromotion from "./pages/IntegrasiPromotion";
import AboutUs from "./pages/AboutUs";

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <React.StrictMode>
    <Router>
      <App>
        <Routes>
          <Route path="/" element={<Home />} />
          <Route path="*" element={<NotFound />} />
          <Route path="/lacak-paket" element={<LacakPaket />} />
          <Route path="/cek-ongkos-kirim" element={<CheckShippingCost />} />
          <Route path="/integrasi-shipping" element={<IntegrasiShipping />} />
          <Route path="/integrasi-promotion" element={<IntegrasiPromotion />} />
          <Route path="/tentang-kami" element={<AboutUs />} />
        </Routes>
      </App>
    </Router>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
