import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { BannerDesktop1, BannerMobile1 } from "../../AssetsImport";

const Banner = () => {
  return (
    <div className="Section Banner">
      <div className="container">
        <h2 className="text-center">
            Promosi
        </h2>
        <div className="d-sm-block d-none text-center">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{ delay: 2500 }}
            centeredSlides={true}
            loop={true}
            modules={[Autoplay]}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <img className="Desktop_banner" src={BannerDesktop1} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
        <div className="d-sm-none d-block">
          <Swiper
            spaceBetween={50}
            slidesPerView={1}
            autoplay={{ delay: 2500 }}
            loop={true}
            modules={[Autoplay]}
            onSwiper={(swiper) => console.log(swiper)}
          >
            <SwiperSlide>
              <img className="Mobile_banner" src={BannerMobile1} alt="" />
            </SwiperSlide>
          </Swiper>
        </div>
      </div>
    </div>
  );
};

export default Banner;
