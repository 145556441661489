import React, { useEffect, useLayoutEffect } from "react";
import { Bg, HeroImg } from "../../AssetsImport";
import { gsap } from "gsap";

import { TextPlugin } from "gsap/TextPlugin";
import { Link } from "react-router-dom";

import ReactGA from "react-ga4";


gsap.registerPlugin(TextPlugin);

const HeaderComponent = () => {
  useEffect(() => {
    // Animasi dengan GSAP
    let ctx = gsap.context(() => {
      gsap.from(".Hero_image", {
        duration: 1,
        opacity: 0,
        y: -50,
        ease: "power2.out",
      });
      gsap.from(".Hero h1", {
        duration: 1,
        opacity: 0,
        y: -50,
        delay: 0.5,
        ease: "power2.out",
      });
      gsap.from(".Hero p", {
        duration: 1,
        opacity: 0,
        y: -50,
        delay: 1,
        ease: "power2.out",
      });
      gsap.from(".Hero button", {
        duration: 1,
        opacity: 0,
        y: -50,
        delay: 1.5,
        ease: "power2.out",
      });
    })

    return () => ctx.revert();
  }, []);

  const handleButtonClick = () => {
    ReactGA.event({
      category: 'User',
      action: 'Clicked cobain sekarang button',
    });
    window.open("https://dashboard.integrasi.co.id/register?utm_source=direct&utm_medium=website_integrasi&utm_campaign=dev");
  };

  return (
    <div className="Section Hero" style={{ backgroundImage: `url('${Bg}')` }}>
      <div className="container">
        <div className="row d-flex">
          <div className="col-sm-7 col-12 my-auto order-sm-0 order-1">
            <h1>
              Optimalkan Promosi dan Pengiriman: Tingkatkan Bisnismu Tanpa
              Repot!
            </h1>
            <p>
              Fokus jualan aja deh, biar kami yang urusin konten dan
              pengirimannya. Dijamin penjualanmu bakal naik sesuai dengan yang
              kamu inginkan.
            </p>
            <button onClick={handleButtonClick} className="btn Btn_purple">Cobain Sekarang</button>
          </div>
          <div className="col-sm-5 col-12 my-auto order-sm-1 order-0">
            <img className="Hero_image mb-sm-0 mb-5" src={HeroImg} alt="" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HeaderComponent;
