import React from "react";
import {
  DefaultFemale,
  DefaultMale,
  Bgi1,
  Bgi2,
  Bgi3,
} from "../../AssetsImport";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFacebook, faInstagram, faTiktok } from "@fortawesome/free-brands-svg-icons";

const OurInfluencers = () => {
  return (
    <div className="Section Our_influencers">
      <div className="container">
        <h2 className="text-center">Influencer Terbaik Kami</h2>
        <p className="text-center">
          Dengan influencer yang kami sediakan, persentase peningkatan penjualan
          produk kamu bisa melonjak sesuai dengan yang kamu mau! Berkolaborasi
          dengan influencer membuka pintu kepada ribuan mata yang penasaran,
          membangun reputasi dan keyakinan, serta memicu gelombang interaksi
          yang memukau dari pengikut mereka. Dengan alat analisis yang canggih,
          kami akan mengupas setiap detail, mengungkap peluang-peluang baru, dan
          membawa bisnis kamu menuju puncak kesuksesan. Siapkan stok produkmu,
          karena permintaan bakal meledak!
        </p>
        <div className="row justify-content-center">
          <div className="col-sm-3 col-12 mb-sm-0 mb-4">
            <div className="card">
              <div className="card-body p-0">
                <img className="card-img-top" src={Bgi1} alt="" />
                <img src={DefaultMale} alt="" className="Photo" />
                <div className="p-2">
                  <div className="Name">John Doe</div>
                  <div className="Socmed">
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faTiktok} className="me-1" />
                        <span>1.2M</span>
                    </div>
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faFacebook} className="me-1" />
                        <span>1.3M</span>
                    </div>
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faInstagram} className="me-1" />
                        <span>2.3M</span>
                    </div>
                  </div>
                  <div className="Desc">
                    <br />
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-12 mb-sm-0 mb-4">
            <div className="card">
              <div className="card-body p-0">
                <img className="card-img-top" src={Bgi1} alt="" />
                <img src={DefaultMale} alt="" className="Photo" />
                <div className="p-2">
                  <div className="Name">John Doe</div>
                  <div className="Socmed">
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faTiktok} className="me-1" />
                        <span>1.2M</span>
                    </div>
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faFacebook} className="me-1" />
                        <span>1.3M</span>
                    </div>
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faInstagram} className="me-1" />
                        <span>2.3M</span>
                    </div>
                  </div>
                  <div className="Desc">
                    <br />
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-sm-3 col-12 mb-sm-0 mb-4">
            <div className="card">
              <div className="card-body p-0">
                <img className="card-img-top" src={Bgi1} alt="" />
                <img src={DefaultMale} alt="" className="Photo" />
                <div className="p-2">
                  <div className="Name">John Doe</div>
                  <div className="Socmed">
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faTiktok} className="me-1" />
                        <span>1.2M</span>
                    </div>
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faFacebook} className="me-1" />
                        <span>1.3M</span>
                    </div>
                    <div className="d-inline me-2 align-items-center">
                        <FontAwesomeIcon icon={faInstagram} className="me-1" />
                        <span>2.3M</span>
                    </div>
                  </div>
                  <div className="Desc">
                    <br />
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OurInfluencers;
