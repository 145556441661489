import "./assets/css/App.css";
import "bootstrap/dist/js/bootstrap.bundle";
import NavbarComponent from "./components/NavbarComponent";
import FooterComponent from "./components/FooterComponent";

import ReactGA from "react-ga4";
import { useEffect } from "react";


function App({ children }) {
  ReactGA.initialize("G-QN13SL3PFV");
  useEffect(() => {
    ReactGA.send({ hitType: "pageview", page: window.location.pathname });
  }, []);
  return (
    <div className="App">
      <NavbarComponent />
      {children}
      <FooterComponent />
    </div>
  );
}

export default App;
