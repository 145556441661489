import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import {
  TikiLogo,
  LalaMoveLogo,
  IdxLogo,
  SicepatLogo,
  DelivereeLogo,
  JNELogo,
  JNTCargoLogo,
  SapxLogo,
  GojekLogo,
  GrabLogo,
  LionParcelLogo,
  SentralCargoLogo,
  WahanaLogo
} from "../../AssetsImport";

const CourierPartners = () => {
  return (
    <div className="Section Courier_partners">
      <div className="container">
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 2500 }}
          loop={true}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay]}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <img className="Courier_logo" src={TikiLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={SicepatLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={IdxLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={LalaMoveLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={DelivereeLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={JNELogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={JNTCargoLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={SapxLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={GojekLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={GrabLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={LionParcelLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={SentralCargoLogo} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Courier_logo" src={WahanaLogo} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default CourierPartners;
