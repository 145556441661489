import React from 'react'
import {AdaIntegrasiLogo } from "../../AssetsImport";


const AdaServices = () => {
  return (
    <div className="Section">
        <div className="container">
          <h2>
            Ini dia beberapa layanan yang bisa bantu ningkatin penjualan dan
            pengiriman, bisa pilih salah satu atau sekaligus juga boleh banget!
          </h2>

          <div className="row justify-content-center">
            <div className="col-sm-5 col-12">
              <div className="card Card mb-sm-0 mb-3 h-100">
                <div className="card-body">
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <img src={AdaIntegrasiLogo} alt="" />
                    <h5 className="card-title">Shipping</h5>
                  </div>
                  <p className="card-text mt-3">
                    Jangan pusing ngatur logistik pengiriman penjualan kamu
                    untuk menentukan mana ekpedisi yang bagus sesuai kebutuhan
                    dan kompetitif, Karna ADAINTEGRASI sebagai aggregator
                    logistik untuk pengiriman terintegrasi sama beberapa
                    logistik top, akan menyesuaikan banget pengiriman sesuai
                    kebutuhanmu dengan harga yang pas! Gak percaya? Cobain aja
                    sekarang.
                  </p>
                  <div className="text-end">
                    <a href="#" className="btn Btn_purple">
                      Selengkapnya
                    </a>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-sm-5 col-12">
              <div className="card Card mb-sm-0 mb-3 h-100">
                <div className="card-body">
                  <div className="d-flex" style={{ alignItems: "center" }}>
                    <img src={AdaIntegrasiLogo} alt="" />
                    <h5 className="card-title">Promotion</h5>
                  </div>
                  <p className="card-text mt-3">
                  Jangan pusing nyari KOL marketing atau agency buat nge-brand produkmu, dengan Integrasi promotion, semuanya jadi mudah. Kita bakal bantu cariin influencer dan afiliasi yang sesuai budget kamu dan analisis dari kita, fokus ke minat audiens target brand dan produk kamu di sosmed. Plus, kita juga akan ngehubungin kamu ke platform marketplace yang pas dengan stok yang kamu punya di sana. Gak percaya? Cobain aja sekarang.
                  </p>
                  <div className="text-end">
                    <a href="#" className="btn Btn_purple">
                      Selengkapnya
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
  )
}

export default AdaServices