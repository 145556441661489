import React from "react";

const Faq = () => {
  return (
    <div className="Section Faq">
      <div className="container">
        <h2 className="text-center">Frequently Asked Question (FAQ)</h2>
        <div className="row justify-content-center">
          <div className="col-sm-8 col-12">
            <div className="accordion" id="accordionExample">
              <div className="accordion-item">
                <h2 className="accordion-header mb-0">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq1"
                    aria-expanded="false"
                    aria-controls="faq1"
                  >
                    <b>Lorem ipsum dolor sit amet?</b>
                  </button>
                </h2>
                <div
                  id="faq1"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quibusdam nesciunt, nostrum voluptatem odio.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header mb-0">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq2"
                    aria-expanded="false"
                    aria-controls="faq2"
                  >
                    <b>Berapa biaya langganannya?</b>
                  </button>
                </h2>
                <div
                  id="faq2"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quibusdam nesciunt, nostrum voluptatem odio.
                  </div>
                </div>
              </div>
              <div className="accordion-item">
                <h2 className="accordion-header mb-0">
                  <button
                    className="accordion-button collapsed"
                    type="button"
                    data-bs-toggle="collapse"
                    data-bs-target="#faq3"
                    aria-expanded="false"
                    aria-controls="faq3"
                  >
                    <b>Lorem ipsum dolor sit amet?</b>
                  </button>
                </h2>
                <div
                  id="faq3"
                  className="accordion-collapse collapse"
                  data-bs-parent="#accordionExample"
                  style={{}}
                >
                  <div className="accordion-body">
                    Lorem ipsum dolor sit amet consectetur adipisicing elit.
                    Quibusdam nesciunt, nostrum voluptatem odio.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Faq;
