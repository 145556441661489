import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay } from "swiper/modules";
import "swiper/css";
import { Blibli, Lazada, Shopee, TiktokShop, Toped } from "../../AssetsImport";

const OurMarketplacePartners = () => {
  return (
    <div className="Section Marketplace_partners">
      <div className="container">
        <h2>Partner Marketplace Kami</h2>
        <p>
          Semua platform marketplace sudah terintegrasi dengan sistem kami, jadi
          kamu nggak perlu repot-repot lagi untuk mengatur pengiriman dan
          promosi produkmu. Cukup satu kali setting, semua marketplace yang kamu
          gunakan akan terintegrasi dengan sistem kami. Dijamin penjualanmu
          bakal naik sesuai dengan yang kamu inginkan.
        </p>
        <br />
        <Swiper
          spaceBetween={50}
          slidesPerView={1}
          autoplay={{ delay: 2500 }}
          loop={true}
          breakpoints={{
            "@0.00": {
              slidesPerView: 1,
              spaceBetween: 10,
            },
            "@0.75": {
              slidesPerView: 2,
              spaceBetween: 20,
            },
            "@1.00": {
              slidesPerView: 3,
              spaceBetween: 40,
            },
            "@1.50": {
              slidesPerView: 4,
              spaceBetween: 50,
            },
          }}
          modules={[Autoplay]}
          onSwiper={(swiper) => console.log(swiper)}
        >
          <SwiperSlide>
            <img className="Marketplace_logo" src={Blibli} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Marketplace_logo" src={Toped} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Marketplace_logo" src={TiktokShop} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Marketplace_logo" src={Lazada} alt="" />
          </SwiperSlide>
          <SwiperSlide>
            <img className="Marketplace_logo" src={Shopee} alt="" />
          </SwiperSlide>
        </Swiper>
      </div>
    </div>
  );
};

export default OurMarketplacePartners;
