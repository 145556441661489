import React from "react";
import { Link, NavLink } from "react-router-dom";
import { AdaIntegrasiLogo, Bg } from "../AssetsImport";

import ReactGA from "react-ga4";

const NavbarComponent = () => {

  const handleButtonClick = () => {
    ReactGA.event({
      category: "User",
      action: "Clicked masuk button",
    });
    window.open(
      "https://dashboard.integrasi.co.id/register?utm_source=direct&utm_medium=website_integrasi&utm_campaign=dev"
    );
  };

  return (
    <nav
      className={`navbar Navbar navbar-expand-lg bg-body-tertiary fixed-top`}
      style={{ backgroundImage: `url('${Bg}')` }}
    >
      <div className="container">
        <Link className="navbar-brand" to="/#">
          <img className="Logo_ada" src={AdaIntegrasiLogo} alt="" />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon" />
        </button>
        <div className="collapse navbar-collapse" id="navbarSupportedContent">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <NavLink className="nav-link active" aria-current="page" to="/">
                Beranda
              </NavLink>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Produk
              </a>
              <ul className="dropdown-menu">
                <li>
                  <NavLink className="dropdown-item" to="/integrasi-shipping">
                    Integrasi Shipping
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/integrasi-promotion">
                    Integrasi Promotion
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                role="button"
                data-bs-toggle="dropdown"
                aria-expanded="false"
              >
                Layanan
              </a>
              <ul className="dropdown-menu">
                <li>
                  <NavLink className="dropdown-item" to="/lacak-paket">
                    Lacak Paket
                  </NavLink>
                </li>
                <li>
                  <NavLink className="dropdown-item" to="/cek-ongkos-kirim">
                    Cek Ongkos Kirim
                  </NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink className="nav-link" to="/tentang-kami">
                Tentang Kami
              </NavLink>
            </li>
          </ul>
          <button
            onClick={handleButtonClick}
            className={`btn Btn_purple btn-sm ms-sm-4 ms-0`}
          >
            Masuk
          </button>
        </div>
      </div>
    </nav>
  );
};

export default NavbarComponent;
